import React, { useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';  // Import Link from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";
import axios from "axios";


const Home = () => {
  const [query, setQuery] = useState(''); // State to store user input
  const [careerData, setCareerData] = useState([]);

  const navigate = useNavigate(); // React Router hook for navigation

  const handleInputChange = (e) => {
    setQuery(e.target.value); // Update the query state as the user types
  };

  const [seoKeywords, setSeoKeywords] = useState("");
  const [seoDescription, setSeoDescription] = useState("");

  useEffect(() => {
    const fetchSEOData = async () => {
    
      const url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1505135608&single=true&output=csv"
      
      try {
        const response = await axios.get(url);
        const keywords = response.data.values;
        const newSeoKeywords = keywords.map((row) => row[0]).join(", ");
        setSeoKeywords(newSeoKeywords);
        setSeoDescription(keywords[0] ? keywords[0][1] : "Default SEO description");  // Assuming description in second column
      } catch (error) {
        console.error("Error fetching SEO data", error);
      }
    };

    fetchSEOData();
  }, []);

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      // Navigate to the desired page or perform a search action
      const searchQuery = query.trim().toLowerCase();
      if (searchQuery) {
        navigate(`/questions/${searchQuery}`); // Example dynamic route
      }
    }
  };
  

  // Fetch data from Google Sheets CSV
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=106539113&single=true&output=csv');
      const data = await response.text();
      const parsedData = data.split('\n').map(row => row.split(','));
      // Extract the first 4 columns
      const careerInfo = parsedData.slice(1).map(row => ({
        title: row[0],
        description: row[1],        
        link: row[2],
      }));
      setCareerData(careerInfo);
    };

    fetchData();
  }, []); // Empty dependency array to run only once on component mount


  return (
    <div>
      <Helmet>
        <meta name="description" content={seoDescription || "Default description"} />
        <meta name="keywords" content={seoKeywords || "Default keywords"} />
        <title>SEO Optimized React App</title>
      </Helmet>
      {/* First Section with a combination of two colors */}
      <section
      style={{
        background: 'linear-gradient(to bottom, rgb(224, 209, 251), rgb(159, 111, 231))',
        padding: '100px 0',
      }}
    >
      <div className="row justify-content-center" style={{ marginLeft: '15px', marginRight: '15px' }}>
        <div className="col-12 col-md-8 text-center">
          <h2 className="text-dark mb-4">
            Which language interview questions do you need to prepare?
          </h2>
          <div className="input-group" style={{ margin: '0 auto', width: '100%', maxWidth: '600px' }}>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Search for language..."
              style={{
                border: '1px solid black', // Thin black border for the search bar
                paddingRight: '50px', // Adding space for the icon inside the input
                fontSize: '18px', // Increase font size
                height: '50px', // Increase height
              }}
              value={query}
              onChange={handleInputChange} // Update query based on input
              onKeyDown={handleSearch} // Trigger search on Enter key
            />
            {/* Search icon on the right side of the search bar */}
            <span
              className="input-group-text"
              style={{
                backgroundColor: '#9965fc',
                border: 'none',
                color: 'white',
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2, // Ensure it stays above the input
              }}
            >
              <i className="fas fa-search-location"></i>
            </span>
          </div>
        </div>
      </div>
    </section>





      <section style={{ backgroundColor: '#e9deff', padding: '60px 0' }}>
  <div className="container text-center">
    <h3 className="mb-5">Build Career with Us</h3>
    <div className="row">
      {careerData.slice(0, 4).map((job, index) => (
        <div key={index} className="col-12 col-md-3 mb-4">
          <div
            className="card"
            style={{
              border: 'none',
              height: '150px',
              background: 'linear-gradient(to right,rgb(241, 121, 119),rgb(231, 179, 106))',
              borderRadius: '15px',
              width: '100%',
            }}
          >
            <div className="card-body d-flex flex-column justify-content-center text-center">
              <h5 className="card-title" style={{ fontSize: '20px' }}>
                {job.title}
              </h5>
              <p>{job.description}</p>
              <Link
                to={job.link}
                className="btn"
                style={{
                  backgroundColor: 'transparent',
                  color: '#9965fc',
                  border: '2px solid #9965fc',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  fontSize: '14px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.3s ease',
                  margin: '0 auto',
                }}
              >
                Apply Now
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
    <Link
      to="/jobs"  // Update this link to match your jobs page path
      className="btn"
      style={{
        backgroundColor: 'transparent',
        color: '#9965fc',
        border: '2px solid #9965fc',
        borderRadius: '20px',
        padding: '10px 20px',
        fontSize: '14px',
        marginTop: '20px',
        transition: 'all 0.3s ease',
      }}
    >
      View All Jobs
    </Link>
  </div>
</section>


<section style={{ backgroundColor: '#f9f9f9', padding: '60px 0' }}>
  <div className="container text-center">
    <div className="row">
      <div className="col-12">
        {/* Horizontal Banner Ad */}
        <div
          style={{
            border: 'none',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            padding: '10px', // Optional padding for better spacing
          }}
        >
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6553013514584902"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-6553013514584902"
            data-ad-slot="7396576655"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>
      </div>
    </div>
  </div>
</section>



      {/* Second Section with cards */}
      <section style={{ backgroundColor: '#e9deff', padding: '60px 0' }}>
        <div className="container text-center">
          <h3 className="mb-5">Explore Interview Questions</h3>
          <div className="row">
            {/* Java Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #ffcccc, #ff9999)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>Java Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/java"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning                   
                  </Link>
                </div>
              </div>
            </div>

            {/* Python Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #ccffcc, #66ff66)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>Python Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/python"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning
                  </Link>
                </div>
              </div>
            </div>

            {/* SQL Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #ffdd99, #ffcc66)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>SQL Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/sql"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning
                  </Link>
                </div>
              </div>
            </div>

            {/* HTML Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #f1c0d1, #f7b7d7)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>HTML Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/html"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning
                  </Link>
                </div>
              </div>
            </div>

            {/* CSS Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #ffccf5, #ff99ff)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>CSS Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/css"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning
                  </Link>
                </div>
              </div>
            </div>

            {/* C Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #cce6ff, #66ccff)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>C Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/c"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning
                  </Link>
                </div>
              </div>
            </div>

            {/* C++ Card */}
            <div className="col-12 col-md-6 mb-4">
              <div
                className="card"
                style={{
                  border: 'none',
                  height: '250px',
                  marginLeft:'11px',
                  background: 'linear-gradient(to right, #f1f1f1, #cccccc)',
                  borderRadius: '15px', // Increased corner radius
                  width: '100%', // Reduced width
                }}
              >
                <div className="card-body d-flex flex-column justify-content-center text-center">
                  <h5 className="card-title" style={{ fontSize: '22px', marginBottom:'30px' }}>C++ Interview Questions</h5> {/* Increased text size */}
                  <Link
                    to="/cpp"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent', // Transparent button background
                      color: '#9965fc',
                      border: '2px solid #9965fc', // Border for the button
                      borderRadius: '20px', // Oval shape
                      padding: '10px 20px', // Reduced button size
                      fontSize: '16px', // Reduced button text size
                      display: 'inline-flex',
                      alignItems: 'center', // Align text and arrow vertically
                      justifyContent: 'center', // Center the content inside the button
                      whiteSpace: 'normal', // Allow text wrapping
                      wordWrap: 'break-word', // Ensure long words break and wrap
                      width: 'auto', // Auto width to fit content
                      transition: 'all 0.3s ease', // Smooth transition on hover
                      margin: '0 auto', // Center button horizontally
                      textAlign: 'center', // Center content inside the button
                    }}
                  >
                    Start Learning
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section style={{ backgroundColor: '#f9f9f9', padding: '60px 0' }}>
  <div className="container text-center">
    <div className="row">
      <div className="col-12">
        {/* Horizontal Banner Ad */}
        <div
          style={{
            border: 'none',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            padding: '10px', // Optional padding for better spacing
          }}
        >
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6553013514584902"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-6553013514584902"
            data-ad-slot="7396576655"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>
      </div>
    </div>
  </div>
</section>


    </div>
  );
};

export default Home;
