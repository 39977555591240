import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Papa from 'papaparse';

const JavaQAPortal = () => {
  const [qaData, setQaData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=949498012&single=true&output=csv')
        .then((response) => response.text())
        .then((csvText) => {
          Papa.parse(csvText, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              setQaData(result.data);
            },
          });
        })
        .catch((err) => console.error('Error fetching data:', err));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ background: '#f9f9f9', minHeight: '100vh' }}>
      {/* SEO Meta Tags */}
      <meta
        name="description"
        content="Explore Java interview questions with detailed answers for job preparation."
      />
      <meta
        name="keywords"
        content="Java interview questions, Java job preparation, OOPs, multithreading, Java collections, Java 8 features"
      />
      <meta name="author" content="Your Name" />
      <meta
        property="og:title"
        content="Java Interview Questions - Job Preparation Portal"
      />
      <meta
        property="og:description"
        content="Prepare for your Java interview with questions covering OOPs, multithreading, collections, Java 8 features, and more."
      />
      <meta
        property="og:image"
        content="https://ahwings.in/java" // Replace with your image URL
      />
      <meta
        property="og:url"
        content="https://ahwings.in/java" // Replace with the page URL
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:site"
        content="@YourTwitterHandle" // Replace with your Twitter handle
      />
      <meta
        name="twitter:title"
        content="Java Interview Questions - Job Preparation Portal"
      />
      <meta
        name="twitter:description"
        content="Prepare for your Java interview with questions covering OOPs, multithreading, collections, Java 8 features, and more."
      />
      <meta
        name="twitter:image"
        content="https://ahwings.in/java" // Replace with your image URL
      />

      <Container className="mt-5">
        <h1
          className="text-center mb-5"
          style={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: '#333',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          Java Interview Questions
        </h1>

        <Row className="justify-content-center">
          {qaData.length > 0 ? (
            qaData.map((qa, index) => (
              <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                <Card
                  style={{
                    backgroundColor: '#935CEB', // Reverted to previous background color
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px', // Ensures uniform card height
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  <Card.Body style={{ flex: 1 }}>
                    <Card.Title style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffffff' }}>
                      <span style={{ color: '#fff' }}>Q{index + 1}: </span>{qa.Question.split('\n')[0]}
                    </Card.Title>

                    <Card.Text style={{ fontSize: '16px', color: '#ffffff' }}>
                      <strong>Answer:</strong>
                      <br />
                      {qa.Answer.split('\n').map((line, idx) => (
                        <span key={idx}>{line}<br /></span>
                      ))}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col sm={12}>
              <h4 className="text-center">Loading questions...</h4>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default JavaQAPortal;
