import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Papa from 'papaparse';
import { Helmet } from 'react-helmet';

const CQAPortal = () => {
  const [qaData, setQaData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch(
        'https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?output=csv'
      )
        .then((response) => response.text())
        .then((csvText) => {
          Papa.parse(csvText, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              setQaData(result.data);
            },
          });
        })
        .catch((err) => console.error('Error fetching data:', err));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div style={{ background: '#f9f9f9', minHeight: '100vh' }}>
      {/* SEO Meta Tags with React Helmet */}
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Comprehensive collection of C interview questions with detailed answers. Prepare for job interviews with topics like pointers, data structures, algorithms, and memory management."
        />
        <meta
          name="keywords"
          content="C interview questions, C job preparation, pointers, data structures, memory management, algorithms, C programming basics, technical interview questions"
        />
        <meta name="author" content="Chethan T R" />
        <meta property="og:title" content="C Interview Questions - Prepare for Technical Interviews" />
        <meta
          property="og:description"
          content="Get a detailed list of C programming interview questions with answers to ace your job interview."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ahwings.in/c" />
        <meta property="og:image" content="https://ahwings.in/c" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="C Interview Questions - Prepare for Technical Interviews"
        />
        <meta
          name="twitter:description"
          content="Ace your next technical interview with this comprehensive list of C programming questions."
        />
        <meta name="twitter:image" content="https://ahwings.in/c" />
        
        {/* Dynamic Meta Tags for each question */}
        {qaData.length > 0 && (
          <title>{`C Interview Question - ${qaData[0].Question.split('\n')[0]}`}</title>
        )}
        {qaData.length > 0 && (
          <meta
            name="description"
            content={`C Interview Question: ${qaData[0].Question.split('\n')[0]}. Learn the answer and improve your interview preparation.`}
          />
        )}
      </Helmet>

      <Container className="mt-5">
        <h1
          className="text-center mb-5"
          style={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: '#333',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          C Interview Questions
        </h1>

        <Row className="justify-content-center">
          {qaData.length > 0 ? (
            qaData.map((qa, index) => (
              <React.Fragment key={index}>
                <Col sm={12} md={6} lg={4} className="mb-4">
                  <Card
                    style={{
                      backgroundColor: '#935CEB',
                      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      transition: 'transform 0.3s ease',
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '300px',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    <Card.Body style={{ flex: 1 }}>
                      <Card.Title
                        style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffffff' }}
                      >
                        <span style={{ color: '#fff' }}>Q{index + 1}: </span>
                        {qa.Question.split('\n')[0]}
                      </Card.Title>

                      <Card.Text style={{ fontSize: '16px', color: '#ffffff' }}>
                        <strong>Answer:</strong>
                        <br />
                        {qa.Answer.split('\n').map((line, idx) => (
                          <span key={idx}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Add Ad after every 2 cards */}
                {(index + 1) % 3 === 0 && (
                  <Col sm={12} className="mb-4">
                    <div
                      style={{
                        border: 'none',
                        backgroundColor: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        overflow: 'hidden',
                        textAlign: 'center',
                      }}
                    >
                      <script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6553013514584902"
                        crossOrigin="anonymous"
                      ></script>
                      <ins
                        className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-6553013514584902"
                        data-ad-slot="8884115815"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                      ></ins>
                      <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                      </script>
                    </div>
                  </Col>
                )}
              </React.Fragment>
            ))
          ) : (
            <Col sm={12}>
              <h4 className="text-center">Loading questions...</h4>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CQAPortal;
