import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Papa from 'papaparse'; // Import Papa Parse for robust CSV parsing
import '../App.css';

const SqlQaPortal = () => {
  const [qaData, setQaData] = useState([]); // State to hold question-answer data

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1410050604&single=true&output=csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Treat the first row as column headers
            skipEmptyLines: true, // Skip empty rows
            complete: (result) => {
              setQaData(result.data); // Set parsed data into state
            },
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 60000); // Polling for live updates every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '#f9f9f9', padding: '20px', minHeight: '100vh' }}>
      {/* SEO Meta Tags */}
      <meta
        name="description"
        content="Explore SQL interview questions and answers. Perfect for job preparation, covering topics like database management, queries, SQL optimization, and more."
      />
      <meta
        name="keywords"
        content="SQL interview questions, SQL coding questions, SQL job interview preparation, SQL tutorials, SQL for beginners, SQL database management, SQL queries, SQL optimization, advanced SQL queries, SQL performance tuning, SQL interview tips, SQL problem-solving, SQL interview practice, SQL data types, SQL subqueries, SQL joins, SQL indexing, SQL triggers, SQL functions, SQL procedures, SQL aggregation, SQL query writing, SQL database design, SQL normalization, SQL data manipulation, SQL DDL, SQL DML, SQL data retrieval, SQL interview questions for experienced, SQL certification preparation, SQL for developers, SQL for data analysts, SQL for software engineers, SQL for data scientists, SQL questions for freshers"
      />
      <meta name="author" content="Your Name or Company" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="SQL Interview Questions - Job Preparation Portal"
      />
      <meta
        property="og:description"
        content="Prepare for your SQL interview with questions covering database management, queries, SQL optimization, and more."
      />
      <meta
        property="og:image"
        content="https://ahwings.in/sql" // Replace with your image URL
      />
      <meta
        property="og:url"
        content="https://ahwings.in/sql" // Replace with your page URL
      />
      
      {/* Twitter Card Meta Tags */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:site"
        content="@YourTwitterHandle" // Replace with your Twitter handle
      />
      <meta
        name="twitter:title"
        content="SQL Interview Questions - Job Preparation Portal"
      />
      <meta
        name="twitter:description"
        content="Prepare for your SQL interview with questions covering database management, queries, SQL optimization, and more."
      />
      <meta
        name="twitter:image"
        content="https://ahwings.in/sql" // Replace with your image URL
      />

      <Container className="mt-5">
        <h1
          className="text-center mb-5"
          style={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: '#333',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          SQL Interview Questions
        </h1>

        <Row className="justify-content-center">
          {qaData.length > 0 ? (
            qaData.map((qa, index) => (
              <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                <Card
                  style={{
                    backgroundColor: '#935CEB', // Set card background color
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px', // Ensures uniform card height
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  <Card.Body style={{ flex: 1 }}>
                    <Card.Title style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffffff' }}>
                      <span style={{ color: '#fff' }}>Q{index + 1}: </span>{qa.Question.split('\n')[0]}
                    </Card.Title>

                    <Card.Text style={{ fontSize: '16px', color: '#ffffff' }}>
                      <strong>Answer:</strong>
                      <br />
                      {qa.Answer.split('\n').map((line, idx) => (
                        <span key={idx}>{line}<br /></span>
                      ))}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col sm={12}>
              <h4 className="text-center">Loading questions...</h4>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default SqlQaPortal;
