import React from 'react';

const RefundPolicy = () => {
  // Inline styles for the page
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    heading: {
      color: '#333',
    },
    subheading: {
      color: '#555',
      marginTop: '15px',
    },
    paragraph: {
      marginBottom: '16px',
    },
    list: {
      marginBottom: '16px',
      paddingLeft: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Refund Policy</h1>
      <p><strong>Effective Date:</strong> [24 December 2024]</p>
      <p>
        [AH wings] we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website ahwings.in, in compliance with global privacy laws and Google's policies.
      </p>
      <p>Please read this Privacy Policy carefully. By accessing or using the Website, you agree to the terms of this Privacy Policy.</p>


      <h2 style={styles.subheading}>1. Refund Eligibility</h2>
      <p style={styles.paragraph}>
        To be eligible for a refund, the following conditions must be met:
      </p>
      <ul style={styles.list}>
        <li>The request must be made within [Insert Number] days of purchase.</li>
        <li>The product or service must not have been used or consumed (where applicable).</li>
        <li>Proof of purchase (e.g., receipt or order confirmation) must be provided.</li>
        <li>The product must be returned in its original condition (if applicable).</li>
      </ul>

      <h2 style={styles.subheading}>2. Non-Refundable Items</h2>
      <p style={styles.paragraph}>
        The following items are not eligible for refunds:
      </p>
      <ul style={styles.list}>
        <li>Downloadable digital products or services that have been accessed.</li>
        <li>Subscription fees after the trial period (if applicable).</li>
        <li>Gift cards and promotional items.</li>
        <li>Products damaged due to misuse.</li>
      </ul>

      <h2 style={styles.subheading}>3. Refund Process</h2>
      <p style={styles.paragraph}>
        To initiate a refund request, please follow these steps:
      </p>
      <ul style={styles.list}>
        <li>Contact our support team at [Insert Email Address] with your order details.</li>
        <li>Provide a clear explanation for your refund request.</li>
        <li>If applicable, return the product to the address provided by our team.</li>
      </ul>
      <p style={styles.paragraph}>
        Once your request is reviewed and approved, the refund will be processed to your original payment method within [Insert Number] business days.
      </p>

      <h2 style={styles.subheading}>4. Late or Missing Refunds</h2>
      <p style={styles.paragraph}>
        If you haven’t received a refund within the expected time frame:
      </p>
      <ul style={styles.list}>
        <li>Check your bank account or payment method again.</li>
        <li>Contact your credit card company or bank as it may take time for the refund to reflect.</li>
        <li>If you’ve done all of this and still haven’t received your refund, please contact us at [Insert Email Address].</li>
      </ul>

      <h2 style={styles.subheading}>5. Changes to This Refund Policy</h2>
      <p style={styles.paragraph}>
        We reserve the right to update or modify this Refund Policy at any time. Any changes will be effective immediately upon posting on this page with an updated "Effective Date."
      </p>

      <h2 style={styles.subheading}>6. Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about this Refund Policy, please contact us at:
      </p>
      <p><strong>Effective Date:</strong> [24 December 2024]</p>
      <p>
        [AH wings] we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website ahwings.in, in compliance with global privacy laws and Google's policies.
      </p>
      <p>Please read this Privacy Policy carefully. By accessing or using the Website, you agree to the terms of this Privacy Policy.</p>


    </div>
  );
};

export default RefundPolicy;
