import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap'; // Import Card and Grid components
import Papa from 'papaparse'; // Import Papa Parse
import { Helmet } from 'react-helmet'; // Import react-helmet for managing SEO tags

const CppQAPortal = () => {
  const [qaData, setQaData] = useState([]); // State to hold question-answer data
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    const fetchData = () => {
      setLoading(true); // Set loading state to true when fetching data
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1444007767&single=true&output=csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Read the first row as column headers
            skipEmptyLines: true, // Skip any empty rows
            complete: (result) => {
              setQaData(result.data); // Set the parsed data
              setLoading(false); // Set loading state to false once data is fetched
            },
          });
        })
        .catch(err => {
          console.error('Error fetching data:', err);
          setLoading(false); // Set loading state to false in case of error
        });
    };

    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 60000); // Polling for live updates every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '', padding: '20px', minHeight: '100vh' }}>
      {/* SEO Meta Tags using react-helmet */}
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Explore a comprehensive collection of C++ interview questions with answers to help you prepare for technical interviews."
        />
        <meta name="keywords" content="C++ interview questions, C++ programming tips, technical interview C++, C++ job preparation" />
        <meta name="author" content="Chethan T R" />
        <meta property="og:title" content="C++ Interview Questions - Prepare for Technical Interviews" />
        <meta property="og:url" content="https://ahwings.in/cpp" />
      </Helmet>

      <div className="container mt-4">
        <h1
          className="text-center mb-5"
          style={{
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          C++ INTERVIEW QUESTIONS
        </h1>

        <Row className="justify-content-center">
          {loading ? (
            <p className="text-center" style={{ fontSize: '20px' }}>
              Loading Questions...
            </p>
          ) : (
            qaData.map((qa, index) => (
              <React.Fragment key={index}>
                <Col sm={12} md={4} className="mb-4">
                  <Card
                    style={{
                      backgroundColor: '#935CEB',
                      height: 'auto',
                      padding: '25px',
                      cursor: 'pointer',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Card.Body>
                      <Card.Title style={{ color: '#ffffff', marginBottom: '15px', fontSize: '18px' }}>
                        <strong>Question:</strong>
                        <br />
                        {qa.Question}
                      </Card.Title>
                      <Card.Text style={{ color: '#ffffff', fontSize: '16px' }}>
                        <strong>Answer:</strong>
                        <br />
                        {qa.Answer}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Insert ad after every 2 cards */}
                {(index + 1) % 3 === 0 && (
                  <Col sm={12} className="text-center mb-4">
                    <script
                      async
                      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6553013514584902"
                      crossOrigin="anonymous"
                    ></script>
                    <ins
                      className="adsbygoogle"
                      style={{ display: 'block' }}
                      data-ad-client="ca-pub-6553013514584902"
                      data-ad-slot="8884115815"
                      data-ad-format="auto"
                      data-full-width-responsive="true"
                    ></ins>
                    <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
                  </Col>
                )}
              </React.Fragment>
            ))
          )}
        </Row>
      </div>
    </div>
  );
};

export default CppQAPortal;
