import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import NavigationBar from './components/Navbar';
import Footer from './components/Footer';

// Import the page components
import Home from './pages/Home';
import Java from './pages/Java';
import Python from './pages/python';
import SQL from './pages/sql';
import HTML from './pages/html';
import CSS from './pages/css';
import C from './pages/c';
import Cpp from './pages/c++';
import Jobs from './pages/Jobs';


import Cookies from './components/cookies'; // Changed the import to use uppercase
import Privacy from './components/privacy'; // Changed to uppercase
import TermsOfUse from './components/termsofuse'; // Changed to uppercase
import Refund from './components/refund'; // Changed to uppercase

const App = () => {
  
  return (
  
    <Router>
      <NavigationBar/>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/java" element={<Java />} />
      <Route path="/python" element={<Python />} />
      <Route path="/sql" element={<SQL />} />
      <Route path="/html" element={<HTML />} />
      <Route path="/css" element={<CSS />} />
      <Route path="/c" element={<C />} />
      <Route path="/cpp" element={<Cpp />} />
      <Route path="/jobs" element={<Jobs />} />     
      <Route path="/cookies" element={<Cookies />} /> {/* Fixed */}
        <Route path="/privacy" element={<Privacy />} /> {/* Fixed */}
        <Route path="/terms" element={<TermsOfUse />} /> {/* Fixed */}
        <Route path="/refund" element={<Refund />} />         
      </Routes>
      <Footer/>
     
    </Router>
  );
};

export default App;