import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaTelegram, FaYoutube, FaLinkedin, FaInstagram } from 'react-icons/fa';  // Import Telegram and YouTube icons

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#333', color: 'white', padding: '30px 0' }}>
      <Container fluid className="text-center">
        <Row>
          {/* Links */}
          <Col xs={12} className="mb-3">
            <Nav className="justify-content-center flex-wrap">
              <Nav.Link href="/jobs" style={{ color: 'white', margin: '5px' }}>Careers</Nav.Link>
              <Nav.Link href="/jobs" style={{ color: 'white', margin: '5px' }}>Jobs</Nav.Link>
              <Nav.Link href="/terms" style={{ color: 'white', margin: '5px' }}>Terms of Use</Nav.Link>
              <Nav.Link href="/privacy" style={{ color: 'white', margin: '5px' }}>Privacy Policy</Nav.Link>
              <Nav.Link href="/refund" style={{ color: 'white', margin: '5px' }}>Refund Policy</Nav.Link>
              <Nav.Link href="cookies" style={{ color: 'white', margin: '5px' }}>Cookies Policy</Nav.Link>
            </Nav>
          </Col>

          {/* Logo, Name, and Social Media Links */}
          <Col xs={12} className="mb-3">
            <img src="logo.png" alt="AH WINGS logo" style={{ width: '120px', marginBottom: '10px' }} />
            <h5>AH Wings</h5>
            <Nav className="justify-content-center">
              <Nav.Link href="https://t.me/ah_wings" target="_blank" style={{ color: 'white', fontSize: '18px', margin: '0 5px' }}>
                <FaTelegram />
              </Nav.Link>
              <Nav.Link href="https://www.youtube.com/@ah_wings" target="_blank" style={{ color: 'white', fontSize: '18px', margin: '0 5px' }}>
                <FaYoutube />
              </Nav.Link>
              <Nav.Link href="" target="_blank" style={{ color: 'white', fontSize: '18px', margin: '0 5px' }}>
                <FaLinkedin />
              </Nav.Link>
              <Nav.Link href="https://www.instagram.com/ah__wings/profilecard/?igsh=MXBkZ3NpeTI3emtucw==" target="_blank" style={{ color: 'white', fontSize: '18px', margin: '0 5px' }}>
                <FaInstagram />
              </Nav.Link>
            </Nav>
          </Col>

          {/* Description */}
          <Col xs={12} className="mb-3">
            <p style={{ fontSize: '14px', margin: '0 10px' }}>
              AH Wings Point is an Ed Tech platform striving to provide the best learning material on technical subjects and provides jobs.
            </p>
          </Col>

          {/* Copyright */}
          <Col xs={12}>
            <p style={{ fontSize: '12px', margin: '0' }}>
              ©AH Wings Copyright 2024. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
