import React from 'react';
import '../App.css'; 

const TermsOfUse = () => {
    return (
        <div className="terms-container">
            <h1>Terms of Use</h1>
            <p>Last Updated: December 24, 2024</p>
            <p>Welcome to our website! By accessing or using our services, you agree to comply with and be bound by the following Terms of Use. Please read these terms carefully.</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing or using this website, you agree to comply with and be bound by these Terms of Use and all applicable laws and regulations. If you do not agree to these terms, please do not use this website.</p>

            <h2>2. Changes to the Terms</h2>
            <p>We reserve the right to modify or replace these Terms of Use at any time. The most recent version will be posted on this page with an updated "Last Updated" date. It is your responsibility to check for any changes.</p>

            <h2>3. User Responsibilities</h2>
            <p>You agree to use our website in accordance with all applicable laws, and not to engage in any activity that could harm or disrupt the functionality of the website or violate any legal rights.</p>

            <h2>4. Privacy and Data Collection</h2>
            <p>We value your privacy. Please refer to our <a href="/privacy-policy">Privacy Policy</a> for information on how we collect and use your data. By using our website, you consent to the data practices outlined in the Privacy Policy.</p>

            <h2>5. Prohibited Activities</h2>
            <p>You may not use our website for any unlawful or prohibited activities, including but not limited to:</p>
            <ul>
                <li>Impersonating others</li>
                <li>Attempting to gain unauthorized access to systems</li>
                <li>Distributing harmful code or malware</li>
                <li>Engaging in fraud or illegal activity</li>
            </ul>

            <h2>6. Intellectual Property</h2>
            <p>The content, features, and functionality on this website, including text, images, logos, and graphics, are the property of the website owner and are protected by intellectual property laws. You may not use any content without prior permission.</p>

            <h2>7. Disclaimers and Limitations of Liability</h2>
            <p>Our website is provided "as is" and without warranties of any kind. We do not guarantee that the website will be available at all times or that it will be error-free. We are not liable for any damages resulting from the use of the website.</p>

            <h2>8. Governing Law</h2>
            <p>These Terms of Use are governed by and construed in accordance with the laws of the jurisdiction in which our company is registered, without regard to its conflict of law principles.</p>

            <h2>9. Third-Party Links</h2>
            <p>Our website may contain links to third-party websites. These links are provided for convenience and do not imply endorsement of the content or practices of those websites. We are not responsible for the content or practices of any third-party sites.</p>

            <h2>10. Contact Information</h2>
            <p>If you have any questions about these Terms of Use, please contact us at <a href="mailto:support@yourwebsite.com">support@yourwebsite.com</a>.</p>

            <p>By using this website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>
        </div>
    );
};

export default TermsOfUse;
