import React from 'react';

const CookiesPolicy = () => {
  // Inline styles for the page
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    heading: {
      color: '#333',
    },
    subheading: {
      color: '#555',
      marginTop: '15px',
    },
    paragraph: {
      marginBottom: '16px',
    },
    list: {
      marginBottom: '16px',
      paddingLeft: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Cookies Policy</h1>
      <p><strong>Effective Date:</strong> [24 December 2024]</p>
      <p>
        [AH wings] we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website ahwings.in, in compliance with global privacy laws and Google's policies.
      </p>
      <p>Please read this Privacy Policy carefully. By accessing or using the Website, you agree to the terms of this Privacy Policy.</p>



      <h2 style={styles.subheading}>1. What Are Cookies?</h2>
      <p style={styles.paragraph}>
        Cookies are small text files that are stored on your device when you visit a website. They allow the website to recognize your device and remember your preferences and actions over time.
      </p>

      <h2 style={styles.subheading}>2. Types of Cookies We Use</h2>
      <ul style={styles.list}>
        <li><strong>Essential Cookies:</strong> These are necessary for the website to function properly and cannot be disabled.</li>
        <li><strong>Performance Cookies:</strong> These collect information about how you use the website to improve its performance.</li>
        <li><strong>Functional Cookies:</strong> These allow the website to remember your preferences and provide enhanced features.</li>
        <li><strong>Advertising Cookies:</strong> These are used to deliver personalized advertisements based on your browsing behavior.</li>
      </ul>

      <h2 style={styles.subheading}>3. How We Use Cookies</h2>
      <p style={styles.paragraph}>
        We use cookies to:
      </p>
      <ul style={styles.list}>
        <li>Ensure the proper functioning of our website.</li>
        <li>Analyze user behavior to improve our services.</li>
        <li>Personalize your experience and remember your preferences.</li>
        <li>Deliver relevant advertisements and measure their effectiveness.</li>
      </ul>

      <h2 style={styles.subheading}>4. Managing Your Cookie Preferences</h2>
      <p style={styles.paragraph}>
        You can manage your cookie preferences through your browser settings. Most browsers allow you to:
      </p>
      <ul style={styles.list}>
        <li>Delete cookies from your device.</li>
        <li>Block cookies or restrict their use.</li>
        <li>Receive notifications before cookies are stored.</li>
      </ul>
      <p style={styles.paragraph}>
        Please note that disabling cookies may affect the functionality of our website.
      </p>

      <h2 style={styles.subheading}>5. Third-Party Cookies</h2>
      <p style={styles.paragraph}>
        We may use third-party cookies from services like Google Analytics or advertising networks. These cookies are governed by the policies of the respective third parties.
      </p>

      <h2 style={styles.subheading}>6. Changes to This Cookies Policy</h2>
      <p style={styles.paragraph}>
        We reserve the right to update or modify this Cookies Policy at any time. Any changes will be effective immediately upon posting on this page with an updated "Effective Date."
      </p>

      <h2 style={styles.subheading}>7. Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about this Cookies Policy, please contact us at:
      </p>
      <p><strong>Email:</strong> pathakashu4289@gmail.com</p>
      <p><strong>Address:</strong> 7th main 2nd cross sadashivanagar tumkur</p>
    </div>
  );
};

export default CookiesPolicy;
