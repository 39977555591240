import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Papa from 'papaparse';

const PythonQAPortal = () => {
  const [qaData, setQaData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1754015940&single=true&output=csv') // Replace with Python CSV URL
        .then((response) => response.text())
        .then((csvText) => {
          Papa.parse(csvText, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              setQaData(result.data);
            },
          });
        })
        .catch((err) => console.error('Error fetching data:', err));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh data every minute
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div style={{ background: '#f9f9f9', minHeight: '100vh' }}>
      {/* SEO Meta Tags */}
      <meta
        name="description"
        content="Explore Python interview questions and answers. Perfect for job preparation, covering topics like Python fundamentals, advanced Python concepts, data structures, and more."
      />
      <meta
        name="keywords"
        content="Python interview questions, Python coding questions, Python job interview preparation, Python tutorials, Python for beginners, Python advanced topics, Python data structures, Python algorithms, Python programming, Python object-oriented programming, Python data analysis, Python machine learning, Python deep learning, Python automation, Python for data scientists, Python for web development, Python Django, Python Flask, Python testing, Python API development, Python for AI"
      />
      <meta name="author" content="Your Name" />
      
      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Python Interview Questions - Job Preparation Portal"
      />
      <meta
        property="og:description"
        content="Prepare for your Python interview with questions covering Python fundamentals, data structures, algorithms, object-oriented programming, machine learning, and more."
      />
      <meta
        property="og:image"
        content="https://ahwings.in/python" // Replace with your image URL
      />
      <meta
        property="og:url"
        content="https://ahwings.in/python" // Replace with the page URL
      />
      
      {/* Twitter Card Meta Tags */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:site"
        content="@YourTwitterHandle" // Replace with your Twitter handle
      />
      <meta
        name="twitter:title"
        content="Python Interview Questions - Job Preparation Portal"
      />
      <meta
        name="twitter:description"
        content="Prepare for your Python interview with questions covering Python fundamentals, data structures, algorithms, object-oriented programming, machine learning, and more."
      />
      <meta
        name="twitter:image"
        content="https://ahwings.in/python" // Replace with your image URL
      />

      <Container className="mt-5">
        <h1
          className="text-center mb-5"
          style={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: '#333',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          Python Interview Questions
        </h1>

        <Row className="justify-content-center">
          {qaData.length > 0 ? (
            qaData.map((qa, index) => (
              <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                <Card
                  style={{
                    backgroundColor: '#935CEB', // Set card background color
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px', // Ensures uniform card height
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  <Card.Body style={{ flex: 1 }}>
                    <Card.Title style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffffff' }}>
                      <span style={{ color: '#fff' }}>Q{index + 1}: </span>{qa.Question.split('\n')[0]}
                    </Card.Title>

                    <Card.Text style={{ fontSize: '16px', color: '#ffffff' }}>
                      <strong>Answer:</strong>
                      <br />
                      {qa.Answer.split('\n').map((line, idx) => (
                        <span key={idx}>{line}<br /></span>
                      ))}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col sm={12}>
              <h4 className="text-center">Loading questions...</h4>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PythonQAPortal;
