import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Papa from 'papaparse'; // Import Papa Parse for robust CSV parsing
import '../App.css';

const HTMLQAPortal = () => {
  const [qaData, setQaData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=327422231&single=true&output=csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Treat the first row as column headers
            skipEmptyLines: true, // Skip empty rows
            complete: (result) => {
              setQaData(result.data); // Set parsed data into state
            },
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh data every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '#f9f9f9', padding: '20px', minHeight: '100vh' }}>
      {/* SEO Meta Tags */}
      <meta
        name="keywords"
        content="HTML interview questions, HTML job preparation, technical interview HTML, HTML for freshers, HTML for experienced developers, HTML attributes, HTML tags, HTML5, semantic HTML, HTML forms, accessibility in HTML, HTML best practices, responsive HTML design, HTML and CSS, frontend development, HTML debugging, modern HTML techniques, advanced HTML concepts, HTML quiz"
      />
      <meta
        name="description"
        content="Explore a comprehensive collection of HTML interview questions and answers to help you prepare for your technical interview. Learn about HTML5, forms, semantic tags, and best practices."
      />
      <meta
        name="author"
        content="Chethan T R"
      />
      <meta
        property="og:title"
        content="HTML Interview Questions - Job Preparation Portal"
      />
      <meta
        property="og:description"
        content="Prepare for your HTML interview with questions covering HTML5, attributes, tags, forms, accessibility, responsive design, and more."
      />
      <meta
        property="og:image"
        content="https://ahwings.in/html" // Replace with your image URL
      />
      <meta
        property="og:url"
        content="https://ahwings.in/html" // Replace with the page URL
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:site"
        content="@ChethanTR"
      />
      <meta
        name="twitter:title"
        content="HTML Interview Questions - Job Preparation Portal"
      />
      <meta
        name="twitter:description"
        content="Prepare for your HTML interview with questions covering HTML5, attributes, tags, forms, accessibility, responsive design, and more."
      />
      <meta
        name="twitter:image"
        content="https://ahwings.in/html" // Replace with your image URL
      />

      <Container className="mt-5">
        <h1
          className="text-center mb-5"
          style={{
            fontSize: '40px',
            fontWeight: 'bold',
            color: '#333',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          HTML Interview Questions
        </h1>

        <Row className="justify-content-center">
          <Col sm={12} className="mb-4">
            {/* Placeholder for additional content */}
          </Col>

          {qaData.length > 0 ? (
            qaData.map((qa, index) => (
              <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                <Card
                  style={{
                    backgroundColor: '#935CEB', // Set card background color
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '300px', // Ensures uniform card height
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  <Card.Body style={{ flex: 1 }}>
                    <Card.Title style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffffff' }}>
                      <span style={{ color: '#fff' }}>Q{index + 1}: </span>{qa.Question.split('\n')[0]}
                    </Card.Title>

                    <Card.Text style={{ fontSize: '16px', color: '#ffffff' }}>
                      <strong>Answer:</strong>
                      <br />
                      {qa.Answer.split('\n').map((line, idx) => (
                        <span key={idx}>{line}<br /></span>
                      ))}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col sm={12}>
              <h4 className="text-center">Loading questions...</h4>
            </Col>
          )}
        </Row>
      </Container>

    </div>
  );
};

export default HTMLQAPortal;
