import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaHome, FaJava, FaPython, FaDatabase, FaHtml5, FaCss3Alt, FaCogs, FaBars, FaBriefcase, FaTelegram, FaYoutube, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link component

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false); // Track navbar expansion

  // Close the navbar when clicked outside
  const handleClickOutside = (e) => {
    if (!e.target.closest('.navbar')) {
      setExpanded(false); // Close navbar
    }
  };

  // Add event listener on mount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Function to close the navbar on mobile when a link is clicked
  const handleNavItemClick = () => {
    setExpanded(false); // Close the navbar when any item is clicked
  };

  return (
    <>
      {/* Social Media Navbar with Text and Icons */}
      <Navbar
        style={{
          backgroundColor: '#9965fc',
          padding: '0',
          position: 'sticky',
          top: '0',
          zIndex: '1030',
        }}
        className="d-none d-lg-flex"
      >
        <Container>
          {/* Centered Text */}
          <Nav className="ms-auto">
            <span
              style={{
                color: 'white',
                fontSize: '18px',
                fontWeight: 'bold',
                letterSpacing: '2px',
              }}
            >
              BUILD YOUR CAREER WITH US
            </span>
          </Nav>

          {/* Social Media Icons on Right with no spacing */}
          <Nav className="ms-auto">
            <Nav.Link
              href="https://t.me/ah_wings"
              target="_blank"
              style={{ color: 'white', fontSize: '14px', margin: '0' }}
            >
              <FaTelegram />
            </Nav.Link>
            <Nav.Link
              href="https://www.youtube.com/@ah_wings"
              target="_blank"
              style={{ color: 'white', fontSize: '14px', margin: '0' }}
            >
              <FaYoutube />
            </Nav.Link>
            <Nav.Link
              href="https://www.linkedin.com"
              target="_blank"
              style={{ color: 'white', fontSize: '14px', margin: '0' }}
            >
              <FaLinkedin />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/ah__wings/profilecard/?igsh=MXBkZ3NpeTI3emtucw=="
              target="_blank"
              style={{ color: 'white', fontSize: '14px', margin: '0' }}
            >
              <FaInstagram />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      {/* Main Navigation Bar */}
      <Navbar
        expand="lg"
        variant="dark"
        className="w-100 custom-navbar"
        style={{
          backgroundColor: '#58368F',
          position: 'sticky',
          top: '0',
          zIndex: '1030',
        }}
        expanded={expanded}
      >
        <Container fluid>
          {/* Logo and AH Wings on the left */}
          <Navbar.Brand
            href="#home"
            className="me-auto"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              src="logo.png" // Replace with your image path
              alt="Logo"
              style={{ width: '60px', height: '50px' }} // Apply white color to logo
            />
          </Navbar.Brand>

          {/* Toggle button for mobile */}
          <Navbar.Toggle
            aria-controls="navbar-nav"
            onClick={() => setExpanded(!expanded)} // Toggle the navbar
          >
            <FaBars />
          </Navbar.Toggle>

          {/* Nav items */}
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              {/* Desktop and mobile menu items */}
              <Nav.Link
                as={Link}
                to="/"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaHome style={{ marginRight: '8px' }} />
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/java"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaJava style={{ marginRight: '8px' }} />
                Java
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/python"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaPython style={{ marginRight: '8px' }} />
                Python
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/sql"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaDatabase style={{ marginRight: '8px' }} />
                SQL
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/html"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaHtml5 style={{ marginRight: '8px' }} />
                HTML
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/css"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaCss3Alt style={{ marginRight: '8px' }} />
                CSS
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/c"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaCogs style={{ marginRight: '8px' }} />
                C
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/cpp"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaCogs style={{ marginRight: '8px' }} />
                C++
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/jobs"
                onClick={handleNavItemClick}
                style={{
                  fontSize: '18px',
                  margin: '0 10px',
                  color: 'white',
                }}
              >
                <FaBriefcase style={{ marginRight: '8px' }} />
                JOBS
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
