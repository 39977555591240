import React from 'react';
import '../App.css'; // Optional: Add a CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> [24 December 2024]</p>
      <p>
        [AH wings] we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website ahwings.in, in compliance with global privacy laws and Google's policies.
      </p>
      <p>Please read this Privacy Policy carefully. By accessing or using the Website, you agree to the terms of this Privacy Policy.</p>

      <h2>1. Information We Collect</h2>
      <h3>1.1. Personal Data</h3>
      <p>Information that you provide directly, such as:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Contact information</li>
        <li>Account details (e.g., username and password)</li>
        <li>Payment information (if applicable)</li>
      </ul>

      <h3>1.2. Non-Personal Data</h3>
      <p>
        Information that is automatically collected when you interact with our Website, such as:
      </p>
      <ul>
        <li>IP address</li>
        <li>Browser type and version</li>
        <li>Operating system</li>
        <li>Referring website</li>
        <li>Pages viewed</li>
        <li>Date and time of visits</li>
        <li>Clickstream data</li>
      </ul>

      <h3>1.3. Cookies and Tracking Technologies</h3>
      <p>
        We use cookies, web beacons, and similar tracking technologies to enhance your experience and gather data about usage patterns. You can manage your cookie preferences through your browser settings.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information collected about you to:</p>
      <ul>
        <li>Provide and maintain our services.</li>
        <li>Personalize your experience on the Website.</li>
        <li>Respond to inquiries and support requests.</li>
        <li>Process payments and transactions.</li>
        <li>Improve website functionality and user experience.</li>
        <li>Send promotional communications (if consented to).</li>
        <li>Comply with legal obligations and resolve disputes.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell your personal information. However, we may share your information with:</p>
      <ul>
        <li><strong>Service Providers:</strong> Third-party vendors who help us with website hosting, analytics, payment processing, etc.</li>
        <li><strong>Legal Authorities:</strong> If required by law or to protect our legal rights.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, sale, or acquisition.</li>
      </ul>

      <h2>4. Your Rights and Choices</h2>
      <p>
        Depending on your location, you may have the following rights:
      </p>
      <ul>
        <li><strong>Access and Portability:</strong> Request a copy of the data we hold about you.</li>
        <li><strong>Correction:</strong> Request corrections to your personal information.</li>
        <li><strong>Deletion:</strong> Request that we delete your personal information.</li>
        <li><strong>Objection:</strong> Object to the processing of your personal data for certain purposes.</li>
        <li><strong>Consent Withdrawal:</strong> Withdraw consent for data processing where consent is required.</li>
      </ul>

      <h2>5. Data Retention</h2>
      <p>We retain your personal data only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>

      <h2>6. Security</h2>
      <p>We implement reasonable technical and organizational measures to protect your information. However, no data transmission or storage system can be guaranteed 100% secure.</p>

      <h2>7. Third-Party Links</h2>
      <p>
        Our Website may contain links to third-party websites. We are not responsible for the privacy practices of these websites and encourage you to read their policies.
      </p>

      <h2>8. Children's Privacy</h2>
      <p>
        Our Website is not intended for children under the age of 13, and we do not knowingly collect personal data from children.
      </p>

      <h2>9. Compliance with Google's Policies</h2>
      <p>
        We adhere to Google's policies, including:
      </p>
      <ul>
        <li>Not collecting sensitive personal information without explicit consent.</li>
        <li>Not engaging in deceptive practices or prohibited activities, as outlined by Google's standards.</li>
      </ul>

      <h2>10. Updates to This Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with a revised "Effective Date."</p>

      <h2>11. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <p><strong>Email:</strong> pathakashu4289@gmail.com</p>
      <p><strong>Address:</strong> 7th main 2nd cross sadashivanagar tumkur</p>
    </div>
  );
};

export default PrivacyPolicy;
